<template>
    <div>
        <breadcrumb
            name="RSA"
            title="Road Side Assistance Requests"
            :subtitle="'Request of ' + request.user_name"
            @onTitleClicked="$emit('cancel')"
        />
        <vx-card title="User Info" class="mb-base">
            <vs-divider />
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">User Name</td>
                                <td class="p-4">{{ request.user_name }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">User Mobile</td>
                                <td class="p-4">{{ request.user_mobile }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">User Email</td>
                                <td class="p-4">{{ request.user_email }}</td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card title="Request Info." class="mb-base">
            <vs-divider />
            <vs-row vs-type="flex" class="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <h4 class="header">User's Car Details</h4>
                        <vs-row>
                            <vs-col vs-lg="4">
                                <vs-divider />
                            </vs-col>
                        </vs-row>
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">VIN</td>
                                <td class="p-4">
                                    {{
                                        request.user_car.vin_number ||
                                        "Undefined"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Car Brand</td>
                                <td class="p-4">
                                    {{
                                        request.user_car.brand_en || "Undefined"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Model Year</td>
                                <td class="p-4">
                                    {{ request.user_car.year || "Undefined" }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Chassis No.</td>
                                <td class="p-4">
                                    {{
                                        request.user_car.chassis_no ||
                                        "Undefined"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Current KMs</td>
                                <td class="p-4">
                                    {{ request.user_car.kilometers + " KM" }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Plate No.</td>
                                <td class="p-4">
                                    {{
                                        request.user_car.license || "Undefined"
                                    }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <h4 class="header">Request Details</h4>
                        <vs-row>
                            <vs-col vs-lg="4">
                                <vs-divider />
                            </vs-col>
                        </vs-row>
                        <table class="sm-table">
                            <tr>
                                <td class="font-semibold p-4">Date / Time</td>
                                <td class="p-4">
                                    {{
                                        request.created_at
                                            | moment("DD MMMM YYYY")
                                    }}
                                    /
                                    {{ request.created_at | moment("h:mm A") }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">
                                    Customer Status
                                    <div class="font-light">
                                        If customer cancel the request or not
                                    </div>
                                </td>
                                <td class="p-4">
                                    <tag-row
                                        :data="
                                            request.status === 'active'
                                                ? 'Active'
                                                : 'Cancelled'
                                        "
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">
                                    Request Status
                                </td>
                                <td class="p-4">
                                    <vs-chip
                                        :color="
                                            getAgentStatusColor(
                                                request.agent_status
                                                    ? request.agent_status
                                                    : 'None'
                                            )
                                        "
                                        class="product-order-status text-center"
                                    >
                                        {{
                                            getAgentStatusState(
                                                request.agent_status
                                                    ? request.agent_status
                                                    : "None"
                                            )
                                        }}
                                    </vs-chip>
                                </td>
                            </tr>
                            <tr v-if="request.agent_comment">
                                <td class="font-semibold p-4">
                                    Agent Comment
                                </td>
                                <td class="p-4">
                                    <p v-html="request.agent_comment"></p>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td class="font-semibold p-4">
                                    Agent Name
                                </td>
                                <td class="p-4">
                                    <span
                                        v-if="
                                            request.agent_status &&
                                                request.agent_status.admin
                                        "
                                    >
                                        {{ request.agent_status.admin.name }}
                                    </span>
                                    <vs-td v-else>None</vs-td>
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">
                                    Agent Email
                                </td>
                                <td class="p-4">
                                    <span
                                        v-if="
                                            request.agent_status &&
                                                request.agent_status.admin
                                        "
                                    >
                                        {{ request.agent_status.admin.email }}
                                    </span>
                                    <vs-td v-else>None</vs-td>
                                </td>
                            </tr> -->
                            <tr v-if="request.cancelation_reason">
                                <td class="font-semibold p-4">
                                    Cancellation Reason
                                </td>
                                <td class="p-4">
                                    {{
                                        request.cancelation_reason ||
                                        "Undefined"
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">
                                    Selected Service Center
                                </td>
                                <td class="p-4">
                                    {{ request.service_center || "Undefined" }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">
                                    User Current Address
                                </td>
                                <td class="p-4">
                                    {{ request.user_address || "Undefined" }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Lat - Lng</td>
                                <td class="p-4">
                                    {{ request.user_lat || "Undefined" }} -
                                    {{ request.user_lng || "Undefined" }}
                                </td>
                            </tr>
                            <tr>
                                <td class="font-semibold p-4">Location</td>
                                <td class="p-4">
                                    <!--                  <span class="flex justify-content-between">-->
                                    <!--                    <a target="_blank" :href="`https://www.google.com/maps/@${request.user_lat},${request.user_lng},16z`"><MapPinIcon></MapPinIcon></a>-->
                                    <a
                                        :href="`http://www.google.com/maps/place/${request.user_lat},${request.user_lng}`"
                                        target="_blank"
                                        >{{
                                            `http://www.google.com/maps/place/${request.user_lat},${request.user_lng}`
                                        }}</a
                                    >
                                    <!--                  </span>-->
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
            </vs-row>
        </vx-card>
        <vx-card title="Update Status" class="mb-base">
            <vs-row vs-type="flex">
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="12"
                    vs-sm="12"
                >
                    <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
                        <table>
                            <tr>
                                <td class="font-semibold p-4">
                                    Request Status
                                </td>
                                <td class="p-4">
                                    <vs-chip
                                        :color="
                                            getAgentStatusColor(
                                                request.agent_status
                                                    ? request.agent_status
                                                    : 'None'
                                            )
                                        "
                                        class="product-order-status text-center"
                                    >
                                        {{
                                            getAgentStatusState(
                                                request.agent_status
                                                    ? request.agent_status
                                                    : "None"
                                            )
                                        }}
                                    </vs-chip>
                                    <feather-icon
                                        icon="EditIcon"
                                        svgClasses="w-6 mt-1 h-6 mr-2 hover:text-warning stroke-current"
                                        @click="
                                            agent_status_update =
                                                !agent_status_update
                                        "
                                        class="ml-12"
                                    />
                                </td>
                            </tr>
                        </table>
                    </div>
                </vs-col>
                <vs-col
                    v-if="agent_status_update"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="4"
                    vs-sm="4"
                >
                    <label>Status</label>
                    <select v-model="agent_status">
                        <option value="pending">Pending</option>
                        <option value="in_progress">In Progress</option>
                        <option value="done">Done</option>
                        <option value="not_reachable">Not Reachable</option>
                    </select>
                </vs-col>
            </vs-row>
            <div class="english" v-if="agent_status_update">
                <small class="label">Agent Comment</small>
                <ckeditor
                    :editor="editor"
                    v-model="agent_comment"
                    :config="editorConfigEn"
                    class="input"
                    v-if="!showHTML"
                />
                <textarea v-model="agent_comment" v-if="showHTML"></textarea>
            </div>
            <div class="optionBtns" v-if="agent_status_update">
                <vs-button
                    class="mr-5 save"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="changeAgentStatus"
                    >Save
                </vs-button>
                <vs-button
                    class="ml-5 cancel"
                    type="border"
                    color="primary"
                    @click="agent_status_update = false"
                    >Cancel
                </vs-button>
            </div>
        </vx-card>
        <vs-button
            color="primary"
            type="filled"
            style="float: right"
            @click="$emit('cancel')"
            >Back
        </vs-button>
        <div class="clr" />
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ChevronsRightIcon, HomeIcon, MapPinIcon } from "vue-feather-icons";
import Breadcrumb from "../../../components/general/breadcrumb";
import AgentStatusRow from "../../../components/table/rows/agent-status-row.vue";
import TagRow from "../../../components/table/rows/tag-row";

export default {
    name: "view-request",
    components: {
        TagRow,
        Breadcrumb,
        ChevronsRightIcon,
        HomeIcon,
        MapPinIcon,
        AgentStatusRow,
    },
    props: {
        request: {
            required: true,
            default: {
                id: null,
                user_id: null,
                user_name: "",
                user_mobile: "",
                user_email: "",
                user_address: "",
                user_lat: "",
                user_lng: "",
                user_car_id: null,
                user_car_name: "",
                status: "",
                cancelation_reason: null,
                service_center_id: null,
                service_center: "",
                service_center_flag: "",
                service_center_lat: null,
                service_center_lng: null,
                service_center_address: null,
                agent_status: "",
                created_at: "",
                agent_comment: ''
            },
        },
    },
    data() {
        return {
            agent_status: this.request.agent_status,
            agent_status_update: false, agent_comment: '', showHTML: false,
            editorConfigEn: {
                placeholder: "Agent Comment",
                language: "en",
            },
            editor: ClassicEditor,
        };
    },
    computed: {
        isActive() {
            if (this.request.status === "active") {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {
        activated(val) {
            this.$http_about
                .get(`sparepart/activation/${this.part.id}/mobile`)
                .then((r) => {
                    console.log(r);
                    this.$emit("update");
                    if (val) {
                        this.$vs.notify({
                            title: "Success",
                            text: "Item is activated successfully",
                            color: "primary",
                        });
                    } else {
                        this.$vs.notify({
                            title: "Success",
                            text: "Item is deactivated successfully",
                            color: "primary",
                        });
                    }
                })
                .catch(() => {
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        text: "Error is occurred!\nCouldn't Save Data!",
                        color: "danger",
                    });
                });
        },
    },
    methods: {
        changeAgentStatus() {
            const payload = {
                request_id: this.request.id,
                request_type: "rsa",
                status: this.agent_status,
                agent_comment: this.agent_comment
            };
            this.$httpAdmin
                .post(`/requests/change-status`, payload, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.auth.token,
                    },
                })
                .then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Request updated",
                        text: "Request updated successfully!",
                        color: "success",
                    });
                    location.reload();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        getAgentStatusColor(status) {
            if (status == "pending") return "dark";
            if (status == "in_progress") return "primary";
            if (status == "done") return "success";
            if (status == "not_reachable") return "warning";
            return "danger";
        },
        getAgentStatusState(status) {
            if (status == "pending") return "Pending";
            if (status == "in_progress") return "In Progress";
            if (status == "done") return "Done";
            if (status == "not_reachable") return "Not Reachable";
            return "Unknown";
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
* {
    margin: 0;
    padding: 0;
}

select {
    width: 90%;
    margin-left: 15px;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
}

.select {
    width: 25%;
    float: right;
    margin-bottom: 30px;
}

.card {
    margin-top: 30px;
}

.product-order-status {
    padding-right: 10px;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.main-component {
    cursor: pointer;
}

.vuesax-app-is-ltr .vs-chip--text {
    margin-left: 10px;
    margin-right: 10px !important;
}

.name {
    font-weight: 900;
}

.header {
    margin: 20px 0px 20px 0px;
    color: grey;
}

.status {
    margin: 0 50px 50px 0;
    float: right;
    color: white;
    padding: 10px;
    border-radius: 10px;
}

.active {
    background-color: #28c76f;
    border-radius: 50px;
    color: white;
}

.cancelled {
    background-color: #ff9f43;
    border-radius: 50px;
    color: white;
}

.clr {
    clear: both;
}

.name {
    font-weight: 900;
}

.main-component {
    cursor: pointer;
}

.reason {
    visibility: hidden;
}

.inline {
    display: inline-block;
}

td {
    text-transform: capitalize;
}
</style>
